import { Error } from "./error";
import { MessageLine } from "./message-line";
import { ErrorLevel } from "./error-level.enum";
import { Response } from "./response";

export class WebsocketConnector {
  private messageIdStep: number = 0;
  private messageMap: { [key: number]: MessageLine } = {};
  private url: string;
  private ws!: WebSocket;
  private retryCounter: number = 0;
  private maxRetryCount: number = 0;
  private tioLoading: any;
  private tioLoaddone: any;

  constructor(url: string) {
    this.url = url;
  }

  private connected!: () => void;

  /**
   * 成功连接后要执行的方法
   * @param fun
   */
  public onConnected(fun: () => void): WebsocketConnector {
    this.connected = fun;
    return this;
  }

  private closed!: () => void;

  /**
   * 连接关闭后要执行的方法
   * @param fun
   */
  public onClosed(fun: () => void): WebsocketConnector {
    this.closed = fun;
    return this;
  }

  private error!: (error: Error) => void;

  /**
   * 发生错误要执行的方法
   * @param fun
   */
  public onError(fun: (error: Error) => void): WebsocketConnector {
    this.error = fun;
    return this;
  }

  private loading!: () => void;

  /**
   * 正在连接/加载数据时要执行的方法
   * @param fun
   */
  public onLoading(fun: () => void): WebsocketConnector {
    this.loading = fun;
    return this;
  }

  private loaddone!: () => void;

  /**
   * 完成连接/数据加载完成要执行的方法
   * @param fun
   */
  public onLoaddone(fun: () => void): WebsocketConnector {
    this.loaddone = fun;
    return this;
  }

  private publicMessage!: (data: any) => void;

  /**
   * 收到公共信息（id=0）要执行的方法
   * @param fun
   */
  public onPublicMessage(fun: (data: any) => void): WebsocketConnector {
    this.publicMessage = fun;
    return this;
  }

  private autoCheckFail!: () => void;

  /**
   * 自动连接检没失败要执行的方法
   * @param fun
   */
  public onAutoCheckFail(fun: () => void): WebsocketConnector {
    this.autoCheckFail = fun;
    return this;
  }


  private timeoutLoading(cld: { cancelSystemLoading: boolean } | null | undefined): void {
    if ((!cld || !cld.cancelSystemLoading) && this.loading) {
      clearTimeout(this.tioLoaddone);
      clearTimeout(this.tioLoading);
      this.tioLoading = setTimeout(this.loading, 500);
    }
  }

  private timeoutLoaddone(cld: { cancelSystemLoadDone: boolean } | null | undefined): void {
    if ((!cld || !cld.cancelSystemLoadDone) && this.loaddone) {
      clearTimeout(this.tioLoading);
      clearTimeout(this.tioLoaddone);
      this.tioLoaddone = setTimeout(this.loaddone, 200);
    }
  }

  /**
   *
   * @param retry 连接失败重试次数
   * @param ml 信息控制对象（当send发送信息重连时应传此参数，其它不用传）
   * @param autoCheck 是否自动连接检测
   * @param fun 连接成功后执行的方法（为null则不执行）
   */
  private doConnect(retry?: number | null | undefined, ml?: MessageLine | null, autoCheck?: boolean, fun?: (() => void) | null): void {
    if (this.ws == null || this.ws.readyState == WebSocket.CLOSED) {
      if (retry != null) {
        this.maxRetryCount = retry;
        this.retryCounter = 0;
      }

      if (this.retryCounter == 0) { //当大于0时表时重试
        // 显示加载动画方法调用
        this.timeoutLoading(ml && ml.beforeSend ? ml.beforeSend() : null);
      }

      this.ws = new WebSocket(this.url);
      this.ws.onopen = (ev: Event) => {
        this.maxRetryCount = 0;
        this.retryCounter = 0;

        //隐藏加载动画方法调用
        this.timeoutLoaddone(ml && ml.beforeReceived ? ml.beforeReceived() : null);

        this.connected && this.connected();
        fun && fun();
      };
      this.ws.onclose = (ev: CloseEvent) => {
        this.closed && this.closed();
      };
      this.ws.onerror = (ev: Event) => {
        if (this.retryCounter < this.maxRetryCount) {
          //重试连接
          this.retryCounter++;
          setTimeout(() => {
            this.doConnect(null, ml, autoCheck, fun);
          }, 500);
        } else {
          this.maxRetryCount = 0;
          this.retryCounter = 0;

          //隐藏加载动画方法调用
          this.timeoutLoaddone(ml && ml.beforeReceived ? ml.beforeReceived() : null);

          if (autoCheck) {
            //自动检测连接时连接错误
            this.autoCheckFail && this.autoCheckFail();
          } else {
            //显示错误方法调用
            const er: Error = { level: ErrorLevel.NetworkError, friendlyMessage: "网络连接错误" };
            const cse: { cancelSystemError: boolean } | null = ml && ml.error && ml.isCatchError(er.level) ? ml.error(er) : null;
            if ((!cse || !cse.cancelSystemError) && this.error) {
              this.error(er);
            }
          }
        }
      };
      this.ws.onmessage = (ev: MessageEvent) => {
        let pd: Response;
        try {
          pd = JSON.parse(ev.data);
        } catch (e: any) {
          //隐藏加载动画方法调用
          this.timeoutLoaddone({ cancelSystemLoadDone: false });

          this.error && this.error({ level: ErrorLevel.System, friendlyMessage: "数据返回错误", detailMessage: e.message ? e.message : e });
          return;
        }
        if (!pd) {
          //隐藏加载动画方法调用
          this.timeoutLoaddone({ cancelSystemLoadDone: false });

          this.error && this.error({ level: ErrorLevel.System, friendlyMessage: "数据返回错误" });
          return;
        }
        const ml = this.messageMap[pd.id];
        if (ml) delete this.messageMap[pd.id];

        //隐藏加载动画方法调用
        this.timeoutLoaddone(ml && ml.beforeReceived ? ml.beforeReceived() : null);

        if (pd.code == 0) {
          if (ml) {
            ml.received && ml.received(pd.data);
          } else {
            this.publicMessage && this.publicMessage(pd.data);
          }
        } else {
          //显示错误方法调用
          const er: Error = pd.data;
          const cse: { cancelSystemError: boolean } | null = ml && ml.error && ml.isCatchError(er.level) ? ml.error(er) : null;
          if ((!cse || !cse.cancelSystemError) && this.error) {
            this.error(pd.data);
          }
        }
      };
    }
  }

  /**
   *
   * @param retry 连接失败重试次数，不设置 为不重试
   */
  public connect(retry?: number): void {
    this.doConnect(retry, null, false, null);
  }

  public autoCheck(): void {
    this.doConnect(null, null, true, null);
  }

  /**
   * 向服务端发送数据。 返回信息控制对象，如定义发送前，接收前，接收，错误等要执行的动作
   * @param action
   * @param data
   * @param requestUrl
   */
  public send(action: string, data: any, requestUrl: string): MessageLine {
    const ml = new MessageLine(++this.messageIdStep);
    this.messageMap[ml.getId()] = ml;

    const fun = () => {
      // 显示加载动画方法调用
      this.timeoutLoading(ml && ml.beforeSend ? ml.beforeSend() : null);

      this.ws.send(JSON.stringify({
        id: ml.getId(),
        action: action,
        requestUrl: requestUrl,
        data: data
      }));
    };

    if (this.ws == null || this.ws.readyState == WebSocket.CLOSED) {
      setTimeout(() => {
        this.doConnect(1, ml, false, fun);
      }, 20);
    } else {
      setTimeout(fun, 20);
    }

    return ml;
  }


}
