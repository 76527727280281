
import FileType from "@/views/ide/filetree/FileType";
import PopResult from "@/components/pop/PopResult";
import IDEPopLet from "@/views/ide/IDEPopLet";

export default class LetOfNewFile extends IDEPopLet {

  private name: string = "";
  private fileType: FileType | null = null;
  private popParams: any = null;

  mounted() {
    // console.log(this.getParams());
    (this.$refs.inputBox as HTMLElement).focus();
    this.popParams = this.getParams();

    let fileTypes = this.popParams?.fileTypes;
    if (fileTypes?.length > 0) {
      this.fileType = fileTypes[0];
    }
  }

  private typeClick(t: FileType): void {
    this.fileType = t;
    (this.$refs.inputBox as HTMLElement).focus();
  }

  protected buttonCall(buttonCode: string): void {
    if (buttonCode == "cancel") {
      this.closePopWin({ action: "B", buttonCode });
    } else if (buttonCode == "ok") {
      this.checkClose({ action: "B", buttonCode });
    }
  }

  private async checkClose(popResult: PopResult): Promise<void> {
    let name = `${this.name}`.trim();
    if (name == "") {
      await this.alert({ content: "名称不能为空" });
      setTimeout(() => (this.$refs.inputBox as HTMLElement).focus(), 100);
      return;
    }
    popResult.result = { name: `${name}${this.fileType ? `.${this.fileType.ext}` : ""}` };
    this.closePopWin(popResult);
  }

  private keyUp(e: any): void {
    switch (e?.key) {
      case "Escape":
        this.closePopWin({ action: "E" });
        break;
      case "Enter":
        this.checkClose({ action: "E" });
        break;
    }
  }
};
