
import IconSymbol from "./views/IconSymbol.vue";
import { Options, Vue } from "vue-class-component";
import PopMange from "./components/pop/PopMange.vue";
import IPlantGround from "@/comm/IPlantGround";

@Options({
  components: { IconSymbol, PopMange }
})
export default class App extends Vue {

  public popManage: PopMange | null | undefined | any;
  public plantGround: IPlantGround | null | undefined | any;

  private resizeHookMap: Map<string, (e: any) => void> = new Map();
  private mouseDownHookMap: Map<string, (e: any) => void> = new Map();
  private mouseMoveHookMap: Map<string, (e: any) => void> = new Map();
  private mouseUpHookMap: Map<string, (e: any) => void> = new Map();

  private userInfo: { user: string, name: string } | null | undefined;


  public mounted() {

    window.addEventListener("resize", this.appResize);
    window.addEventListener("mousedown", this.appMouseDown);
    window.addEventListener("mousemove", this.appMouseMove);
    window.addEventListener("mouseup", this.appMouseUp);

    this.popManage = this.$refs.popManage as PopMange;
  }

  unmounted() {
    window.removeEventListener("resize", this.appResize);
    window.removeEventListener("mousedown", this.appMouseDown);
    window.removeEventListener("mousemove", this.appMouseMove);
    window.removeEventListener("mouseup", this.appMouseUp);
  }

  public appResize(e: any): void {
    this.resizeHookMap.forEach((fun: (e: any) => void, key: string) => fun(e));
  }

  public appMouseDown(e: any): void {
    this.mouseDownHookMap.forEach((fun: (e: any) => void, key: string) => fun(e));
  }

  public appMouseMove(e: any): void {
    this.mouseMoveHookMap.forEach((fun: (e: any) => void, key: string) => fun(e));
  }

  public appMouseUp(e: any): void {
    this.mouseUpHookMap.forEach((fun: (e: any) => void, key: string) => fun(e));
  }


  public resizeHook(key: string, fun: (e: any) => void): void {
    this.resizeHookMap.set(key, fun);
  }

  public removeResizeHook(key: string): void {
    this.resizeHookMap.delete(key);
  }


  public hookMouseDown(key: string, fun: (e: any) => void): void {
    this.mouseDownHookMap.set(key, fun);
  }

  public removeHookMouseDown(key: string): void {
    this.mouseDownHookMap.delete(key);
  }

  public hookMouseMove(key: string, fun: (e: any) => void): void {
    this.mouseMoveHookMap.set(key, fun);
  }

  public hookMouseUp(key: string, fun: (e: any) => void): void {
    this.mouseUpHookMap.set(key, fun);
  }

  public removeHookMouseMove(key: string): void {
    this.mouseMoveHookMap.delete(key);
  }

  public removeHookMouseUp(key: string): void {
    this.mouseUpHookMap.delete(key);
  }


  public getUserInfo(): { user: string, name: string } | null | undefined {
    return this.userInfo;
  }

  public setUserInfo(info: { user: string, name: string }): void {
    this.userInfo = info;
  }

};
