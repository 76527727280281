import {Error} from "./error";
import {MessageLine} from "./message-line";
import {ErrorLevel} from "./error-level.enum";
import {Response} from "./response";
import Axios, {AxiosResponse} from "axios";

export class HttpConnector {
    private messageIdStep: number = 0;
    private url: string;
    private tioLoading: any;
    private tioLoaddone: any;

    constructor(url: string) {
        this.url = url;
    }


    private error!: (error: Error) => void;

    /**
     * 发生错误要执行的方法
     * @param fun
     */
    public onError(fun: (error: Error) => void): HttpConnector {
        this.error = fun;
        return this;
    }

    private loading!: () => void;

    /**
     * 正在连接/加载数据时要执行的方法
     * @param fun
     */
    public onLoading(fun: () => void): HttpConnector {
        this.loading = fun;
        return this;
    }

    private loaddone!: () => void;

    /**
     * 完成连接/数据加载完成要执行的方法
     * @param fun
     */
    public onLoaddone(fun: () => void): HttpConnector {
        this.loaddone = fun;
        return this;
    }

    private timeoutLoading(cld: { cancelSystemLoading: boolean } | null | undefined): void {
        if ((!cld || !cld.cancelSystemLoading) && this.loading) {
            clearTimeout(this.tioLoaddone);
            clearTimeout(this.tioLoading);
            this.tioLoading = setTimeout(this.loading, 500);
        }
    }

    private timeoutLoaddone(cld: { cancelSystemLoadDone: boolean } | null | undefined): void {
        if ((!cld || !cld.cancelSystemLoadDone) && this.loaddone) {
            clearTimeout(this.tioLoading);
            clearTimeout(this.tioLoaddone);
            this.tioLoaddone = setTimeout(this.loaddone, 200);
        }
    }

    /**
     * 向服务端发送数据。 返回信息控制对象，如定义发送前，接收前，接收，错误等要执行的动作
     * @param action
     * @param data
     * @param requestUrl
     */
    public send(action: string, data: any, requestUrl: string): MessageLine {
        const ml = new MessageLine(++this.messageIdStep);

        setTimeout(() => {

            // 显示加载动画方法调用
            this.timeoutLoading(ml && ml.beforeSend ? ml.beforeSend() : null);

            // Axios.defaults.withCredentials = true;
            Axios.post<Response, AxiosResponse<Response>>(this.url, {id: ml.getId(), action: action, requestUrl: requestUrl, data: data}, {withCredentials: true}).then(
                (res: AxiosResponse<Response>) => {
                    const result = res.data;
                    //隐藏加载动画方法调用
                    this.timeoutLoaddone(ml && ml.beforeReceived ? ml.beforeReceived() : null);

                    if (!result) {
                        this.error && this.error({level: ErrorLevel.System, friendlyMessage: "数据返回错误"});
                        return;
                    }

                    if (result.code == 0) {
                        ml.received && ml.received(result.data);
                    } else {
                        //显示错误方法调用
                        const er: Error = result.data;
                        const cse: { cancelSystemError: boolean } | null = ml && ml.error && ml.isCatchError(er.level) ? ml.error(er) : null;
                        if ((!cse || !cse.cancelSystemError) && this.error) {
                            this.error(result.data);
                        }
                    }
                },
                (err: any) => {
                    //隐藏加载动画方法调用
                    this.timeoutLoaddone(ml && ml.beforeReceived ? ml.beforeReceived() : null);

                    //显示错误方法调用
                    const er: Error = {level: ErrorLevel.NetworkError, friendlyMessage: "网络连接错误", detailMessage: err.message};
                    const cse: { cancelSystemError: boolean } | null = ml && ml.error && ml.isCatchError(er.level) ? ml.error(er) : null;
                    if ((!cse || !cse.cancelSystemError) && this.error) {
                        this.error(er);
                    }
                }
            );
        }, 20);

        return ml;
    }
}
