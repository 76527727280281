import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import { EstransferService } from "@/comm/estransfer/estransfer.service";

import "@/assets/scss/app.scss";


const app = createApp(App);

let wsUrl: string = process.env.VUE_APP_BIZ_WS;
if (!wsUrl.startsWith("ws://") && !wsUrl.startsWith("wss://")) {
  wsUrl = `${window.location.protocol == "https:" ? "wss://" : "ws://"}${window.location.host}${wsUrl.startsWith("/") ? "" : "/"}${wsUrl}`;
}
// websocketAutoConnect: false : websocket 断开后不自动连接，如果有请求后再尝试连接
app.config.globalProperties.$etf = new EstransferService().init({ websocketUrl: wsUrl, httpUrl: process.env.VUE_APP_BIZ_URL, websocketAutoConnect: false });
app.use(store).use(router).mount("#app");


router.beforeEach((to, from, next) => {
  /* 路由发生变化修改页面title */
  if (to.meta.title) {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    document.title = to.meta.title;
  }
  next();
});