import { WebsocketConnector } from "./websocket-connector";
import { Error } from "./error";
import { MessageLine } from "./message-line";
import { HttpConnector } from "./http-connector";
import { ErrorLevel } from "./error-level.enum";

export class EstransferService {


  /**
   * 连接模式 websocket,http
   */
  private connectMode: string = "http";

  private socketConnector!: WebsocketConnector;
  private httpConnector!: HttpConnector;

  private isSocketConnected: boolean = false;

  private _autoConnect: boolean = true;
  get autoConnect(): boolean {
    return this._autoConnect;
  }

  set autoConnect(value: boolean) {
    this._autoConnect = value;
  }

  private httpUrl: string | undefined;
  private websocketUrl: string | undefined;

// constructor(private http: HttpClient, private location: PlatformLocation) {
  //
  // }


  /** ============================================
   * websocket连接独有的事件
   *  ============================================
   */

  private connected?: () => void;

  /**
   * 使用Websocket连接时，连接成功执行的方法 fun
   * @param fun
   */
  public onConnected(fun: () => void): EstransferService {
    this.connected = fun;
    return this;
  }

  private closed?: () => void;

  /**
   * 使用Websocket连接时，连接关闭后要执行的方法 fun
   * @param fun
   */
  public onClosed(fun: () => void): EstransferService {
    this.closed = fun;
    return this;
  }

  private publicMessage?: (data: any) => void;

  /**
   * 使用Websocket连接时，收到公共信息（id=0）要执行的方法 fun
   * @param fun
   */
  public onPublicMessage(fun: (data: any) => void): EstransferService {
    this.publicMessage = fun;
    return this;
  }


  /** ============================================
   * websocket与http连接共有的事件
   *  ============================================
   */

  private error?: (error: Error) => void;

  /**
   * 发生错误要执行的方法
   * @param fun
   */
  public onError(fun: (error: Error) => void): EstransferService {
    this.error = fun;
    return this;
  }

  private loading?: () => void;

  /**
   * 正在连接/加载数据时要执行的方法
   * @param fun
   */
  public onLoading(fun: () => void): EstransferService {
    this.loading = fun;
    return this;
  }

  private loaddone?: () => void;

  /**
   * 完成连接/数据加载完成要执行的方法
   * @param fun
   */
  public onLoaddone(fun: () => void): EstransferService {
    this.loaddone = fun;
    return this;
  }

  private autoCheckTIO: any;

  private doAutoCheckWebsocket(timeout?: number): void {
    clearTimeout(this.autoCheckTIO);
    this.autoCheckTIO = setTimeout(() => {
      this.socketConnector.autoCheck();
    }, timeout || 10000);
  }

  public init(config: { websocketUrl?: string, httpUrl?: string, websocketAutoConnect?: boolean }): EstransferService {

    this.httpUrl = config.httpUrl;
    this.websocketUrl = config.websocketUrl;
    this.autoConnect = config.websocketAutoConnect ? true : false;

    this.httpUrl && this.initHttp(this.httpUrl);

    if (this.websocketUrl) {
      if (this.connectMode == "http") {
        this.send("WSHandshake", {}).onReceived(
          (data) => {
            if (data && data.success) {
              // @ts-ignore
              this.initWS(this.websocketUrl, this.httpUrl);
            }
          }
        );
      } else {
        this.initWS(this.websocketUrl, this.httpUrl);
      }
    }

    return this;

  }

  private initHttp(httpUrl: string): void {
    this.httpConnector = new HttpConnector(httpUrl);
    // http 连接器事件
    this.httpConnector
      .onError((err: Error) => {
        this.error && this.error(err);
      })
      .onLoading(() => {
        this.loading && this.loading();
      })
      .onLoaddone(() => {
        this.loaddone && this.loaddone();
      });
  }

  private initWS(websocketUrl: string, httpUrl?: string): void {
    if (this.socketConnector) return;
    this.socketConnector = new WebsocketConnector(websocketUrl);
    // websocket 连接器事件
    this.socketConnector
      .onConnected(() => {
        this.connectMode = "websocket";
        this.isSocketConnected = true;
        this.connected && this.connected();
      })
      .onClosed(() => {
        httpUrl && (this.connectMode = "http");
        this.isSocketConnected && this.closed && this.closed();
        this.isSocketConnected = false;
        this.autoConnect && this.doAutoCheckWebsocket();
      })
      .onPublicMessage((data: any) => {
        this.publicMessage && this.publicMessage(data);
      })
      .onError((err: Error) => {
        this.error && this.error(err);
      })
      .onLoading(() => {
        this.loading && this.loading();
      })
      .onLoaddone(() => {
        this.loaddone && this.loaddone();
      })
      .onAutoCheckFail(() => {
        this.autoConnect && this.doAutoCheckWebsocket();

      })
      .autoCheck();
  }


  public send(action: string, data: any): MessageLine {

    const errFun: (friendlyMessage: string, detailMessage: string) => MessageLine = (friendlyMessage: string, detailMessage: string) => {
      const ml: MessageLine = new MessageLine(0);
      setTimeout(() => {
        ml.fireError({ level: ErrorLevel.System, friendlyMessage: friendlyMessage, detailMessage: detailMessage });
      }, 10);
      return ml;
    };

    switch (this.connectMode) {
      case "websocket":
        if (this.socketConnector != null) {
          return this.socketConnector.send(action, data, window.location.href);
        } else {
          return errFun("连接错误", "socketConnector未初始化");
        }
      case "http":
        if ("WSHandshake" != action && this.websocketUrl) {
          if (this.socketConnector == null) {
            this.initWS(this.websocketUrl, this.httpUrl); // 初始化websocket连接
          } else {
            this.doAutoCheckWebsocket(100);  // 尝试websocket连接
          }
        }
        if (this.httpConnector != null) {
          return this.httpConnector.send(action, data, window.location.href);
        } else {
          return errFun("连接错误", "httpConnector未初始化");
        }
      default:
        return errFun("连接错误", "未定义的connectMode:" + this.connectMode);
    }
  }

}
