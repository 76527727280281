import { createRouter, createWebHashHistory, RouteRecordRaw } from "vue-router";


const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    name: "mainview",
    component: () => import("../views/MainView.vue"),
    children: [
      {
        path: "/projectlist",
        component: () => import("../views/projects/ProjectList.vue"),
      }
    ]
  },
  {
    path: "/about",
    name: "about",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/AboutView.vue")
  },
  {
    path: "/editor",
    name: "editor",
    component: () => import("../views/EditorView.vue")
  }, {
    path: "/editorn",
    name: "editorn",
    component: () => import("../views/EditorViewN.vue")
  }, {
    path: "/ide/:id",
    name: "ide",
    component: () => import("../views/ide/WebIDE.vue"),
    meta: {
      title: "项目学习"
    }
  }
];

const router = createRouter({
  history: createWebHashHistory(),
  routes
});

export default router;
