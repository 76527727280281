
import PopResult from "@/components/pop/PopResult";
import IDEPopLet from "@/views/ide/IDEPopLet";

export default class LetOfFileRename extends IDEPopLet {

  private newName: string = "";


  mounted() {
    this.newName = this.getParams().name;
    (this.$refs.inputBox as HTMLElement).focus();
  }

  protected buttonCall(buttonCode: string): void {
    if (buttonCode == "cancel") {
      this.closePopWin({ action: "B", buttonCode });
    } else if (buttonCode == "ok") {
      this.checkClose({ action: "B", buttonCode });
    }

  }

  private async checkClose(popResult: PopResult): Promise<void> {
    let name = `${this.newName}`.trim();
    if (name == "") {
      await this.alert({ content: "名称不能为空" });
      setTimeout(() => (this.$refs.inputBox as HTMLElement).focus(), 100);
      return;
    }
    popResult.result = { newName: name };
    this.closePopWin(popResult);
  }

  private keyUp(e: any): void {
    switch (e?.key) {
      case "Escape":
        this.closePopWin({ action: "E" });
        break;
      case "Enter":
        this.checkClose({ action: "E" });
        break;
    }
  }
};
