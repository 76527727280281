import { Vue } from "vue-class-component";
import App from "@/App.vue";
import PopConfig from "@/components/pop/PopConfig";
import { EstransferService } from "@/comm/estransfer/estransfer.service";
import { Error } from "@/comm/estransfer/error";
import { ErrorLevel } from "@/comm/estransfer/error-level.enum";
import IPlantGround from "@/comm/IPlantGround";
import PopMange from "@/components/pop/PopMange.vue";
import LightConfig from "@/components/pop/LightConfig";

export default abstract class ESVue extends Vue {
  // @ts-ignore
  protected app!: App;

  public mounted(): void {
    // @ts-ignore
    this.app = this.$root as App;
  }

  protected setPlantGround(plantGround: IPlantGround): void {
    this.app.plantGround = plantGround;
    let etf: EstransferService = ((this as any).$etf as EstransferService);
    etf.onConnected(plantGround.socketConnected);
    etf.onClosed(plantGround.socketClosed);
  }

  protected clearPlantGround(): void {
    this.app.plantGround = null;
  }

  private getPlantGround(): IPlantGround {
    return this.app?.plantGround;
  }

  private getPopManage(): PopMange {
    return this.app?.popManage;
  }

  protected triggerResize(): void {
    this.app?.appResize(null);
  }

  protected resizeHook(key: string, fun: (e: any) => void): void {
    this.app?.resizeHook(key, fun);
  }

  protected removeResizeHook(key: string): void {
    this.app?.removeResizeHook(key);
  }

  protected hookMouseDown(key: string, fun: (e: any) => void): void {
    this.app?.hookMouseDown(key, fun);
  }

  protected removeHookMouseDown(key: string): void {
    this.app?.removeHookMouseDown(key);
  }

  protected hookMouseMove(key: string, fun: (e: any) => void): void {
    this.app?.hookMouseMove(key, fun);
  }

  protected removeHookMouseMove(key: string): void {
    this.app?.removeHookMouseMove(key);
  }

  protected hookMouseUp(key: string, fun: (e: any) => void): void {
    this.app?.hookMouseUp(key, fun);
  }

  protected removeHookMouseUp(key: string): void {
    this.app?.removeHookMouseUp(key);
  }

  protected getUserInfo(): { user: string, name: string } | null | undefined {
    return this.app?.getUserInfo();
  }

  public esPost(action: string, data: any, events?: {
    beforeSend?: () => { cancelSystemLoading: boolean },
    beforeReceived?: () => { cancelSystemLoadDone: boolean },
    beforeError?: (err: Error) => { cancelDefault: boolean } // 处理错误之前调用(会话超时的错误除外)
  }): Promise<any> {
    return new Promise<any>(resolve => {
        ((this as any).$etf as EstransferService).send(
          action, data
        ).onBeforeSend(() => {
          let res: { cancelSystemLoading: boolean } = { cancelSystemLoading: false };
          if (events?.beforeSend) {
            res = events?.beforeSend();
            !res.cancelSystemLoading && this.getPlantGround()?.loading();
          }
          return res;
        }).onBeforeReceived(() => {
          let res: { cancelSystemLoadDone: boolean } = { cancelSystemLoadDone: false };
          if (events?.beforeReceived) {
            res = events?.beforeReceived();
            !res.cancelSystemLoadDone && this.getPlantGround()?.hideLoad();
          }
          return res;
        }).onReceived(
          (data: any) => resolve(data)
        ).onError((err: Error) => {
            switch (err.level) {
              case ErrorLevel.SessionTimeout:
                this.sessionTimeoutDo(action, data, resolve); // 会话超时
                return { cancelSystemError: true };
              case ErrorLevel.User:
              case ErrorLevel.System:
              case ErrorLevel.PermissionDenied:
              case ErrorLevel.NetworkError:
              default:
                if (events?.beforeError) {
                  if (events?.beforeError(err)?.cancelDefault) {
                    return { cancelSystemError: true };
                  }
                }
                return this.getPlantGround()?.showErrorMessage(err) || { cancelSystemError: false };
            }
          },
          [ErrorLevel.System, ErrorLevel.NetworkError, ErrorLevel.SessionTimeout, ErrorLevel.PermissionDenied, ErrorLevel.User]
        );
      }
    );
  }

  private async sessionTimeoutDo(action: string, data: any, resolve: (value: any) => void): Promise<void> {
    let pg: IPlantGround = this.getPlantGround();
    if (!pg) {
      this.alert({ title: "", content: "会话超时。" });
      return;
    }
    if (!pg.sessionTimeout()) return; // 中止默认操作
    let result: { user: string, name: string } = await pg.login(); // 弹出登录窗口
    if (!result) {
      // 登录失败
      return;
    }
    this.app?.setUserInfo(result);
    resolve(await this.esPost(action, data));
  }

  protected async popWin(config?: PopConfig): Promise<void> {
    config && (config.cssClass = this.getBaseCssClass());
    return this.getPopManage()?.popWin(config);
  }

  protected async alert(config: { title?: string, content: string, buttons?: { code: string, text: string, default?: boolean, keys?: string[] }[], buttonsAlign?: string }): Promise<void> {
    let c: any = { ...config };
    delete c.content;

    c.cssClass = this.getBaseCssClass();
    if (typeof (c.buttons) == "undefined") {
      c.buttons = [{ code: "ok", text: "确定", default: true, keys: ["Escape", "Enter"] }];
    }
    if (typeof (c.buttonsAlign) == "undefined") {
      c.buttonsAlign = "center";
    }

    c.letKey = "Alert";
    c.minWidth = "200px";
    c.params = { content: config.content };
    c.mask = true;

    return this.getPopManage()?.popWin(c);
  }

  protected popLight(config: LightConfig): void {
    config.cssClass = this.getBaseCssClass();
    config.count = 1;
    this.getPopManage()?.popLight(config);
  }

  protected abstract getBaseCssClass(): string;


}