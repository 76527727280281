import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, vModelText as _vModelText, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  class: "flex-column mx-start sx-stretch",
  style: {"flex":"0 0 auto","padding":"0px 10px 10px 10px"}
}
const _hoisted_2 = {
  class: "flex-txt-left-center",
  style: {"flex":"0 0 32px"}
}
const _hoisted_3 = {
  class: "flex-row mx-start sx-stretch",
  style: {"flex":"0 0 32px"}
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, _toDisplayString(this.popInfo?.config.params?.name) + " 重命名为", 1),
    _createElementVNode("div", _hoisted_3, [
      _withDirectives(_createElementVNode("input", {
        ref: "inputBox",
        type: "text",
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.newName) = $event)),
        style: {"flex":"1 1 auto","width":"100px"},
        onKeyup: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.keyUp && _ctx.keyUp(...args)))
      }, null, 544), [
        [_vModelText, _ctx.newName]
      ])
    ])
  ]))
}