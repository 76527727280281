
import { Vue } from "vue-class-component";
import { Prop } from "vue-property-decorator";
import PopMange from "./PopMange.vue";
import PopItem from "@/components/pop/PopItem";
import PopResult from "@/components/pop/PopResult";


export default class PopWin extends Vue {
  @Prop(Object) popInfo!: PopItem;

  private cssStyle: string = "";

  private twinkleBorderCss: string = "";

  private buttonsClass: string = "mx-end";

  private isShowBottomBar: boolean = true;

  private reSetSssStyle(): void {
    if (this.popInfo) {
      let a: string[] = [];
      a.push(`z-index:${this.popInfo.zIndex};min-width:${this.popInfo.config.minWidth};min-height:${this.popInfo.config.minHeight}`);
      this.popInfo.config.width && a.push(`width:${this.popInfo.config.width}`);
      this.popInfo.config.height && a.push(`height:${this.popInfo.config.height}`);
      let model: string = this.popInfo.config.position?.model || "";
      let x: number = this.popInfo.config.position?.x || 0;
      let y: number = this.popInfo.config.position?.y || 0;
      switch (model) {
        case "percent":
          x = Math.min(Math.max(0, x), 100);
          y = Math.min(Math.max(0, y), 100);
          a.push(`left: ${x}%;top: ${y}%;-webkit-transform: translate(-${x}%, -${y}%);transform: translate(-${x}%, -${y}%)`);
          break;
        case "pixel":
          a.push(`left: ${x}px;top: ${y}px`);
          break;
      }
      this.cssStyle = a.join(";");
    }
  }

  mounted(): void {
    this.popInfo && (this.popInfo.ref = this);
    this.reSetSssStyle();
    switch (this.popInfo?.config.buttonsAlign) {
      case "left":
        this.buttonsClass = "mx-start";
        break;
      case "center":
        this.buttonsClass = "mx-center";
        break;
      case "right":
      default:
        this.buttonsClass = "mx-end";
        break;
    }
    let buttons = this.popInfo?.config?.buttons;
    if (buttons) {
      let defaultIndex: number = -1;
      for (let i = 0; i < buttons.length; i++) {
        if (buttons[i].default) {
          defaultIndex = i;
          break;
        }
      }
      if (defaultIndex > -1) {
        (this.$refs.btn as Array<HTMLElement>)[defaultIndex].focus();
      }
    }
  }

  unmounted(): void {
    this.removeEventHook();
  }

  private buttonCallOfPopLet?: (code: string) => void;

  public hookButtonCall(c: (buttonCode: string) => void): void {
    this.buttonCallOfPopLet = c;
  }

  private isCallClose: boolean = false;

  private callClose(result: PopResult): void {
    if (this.isCallClose) return;
    this.isCallClose = true;
    (this.$parent as PopMange).closePopWin(this.popInfo, result);
  }

  private xClose(): void {
    this.callClose({ action: "X" });
  }

  public closePopWin(result: PopResult): void {
    this.callClose(result);
  }

  private buttonClick(code: string): void {
    this.buttonCallOfPopLet && this.buttonCallOfPopLet(code);
  }

  private removeEventHook(): void {
    (this.$parent as PopMange).$emit("removeHookMouseMove", `popWin-${this.$.uid}`);
    (this.$parent as PopMange).$emit("removeHookMouseUp", `popWin-${this.$.uid}`);
  }


  private movingInfo?: { oriTop: number, oriLeft: number, mouseDownX: number, mouseDownY: number } | null | undefined;

  private titleBarMouseDown(e: any): void {
    let h = (this.$refs.popWin as HTMLElement);
    this.movingInfo = { oriTop: h.offsetTop, oriLeft: h.offsetLeft, mouseDownX: e.clientX, mouseDownY: e.clientY };
    (this.$parent as PopMange).$emit("hookMouseMove", `popWin-${this.$.uid}`, this.winMove);
    (this.$parent as PopMange).$emit("hookMouseUp", `popWin-${this.$.uid}`, (e: any) => {
      this.movingInfo = null;
      this.removeEventHook();
    });
  }

  private winMove(e: any): void {
    if (!this.movingInfo) return;
    let position = this.popInfo.config.position;
    if (!position) return;
    let model: string = position.model || "";
    if (model != "pixel") {
      position.model = "pixel";
    }
    position.x = this.movingInfo.oriLeft + (e.clientX - this.movingInfo.mouseDownX);
    position.y = this.movingInfo.oriTop + (e.clientY - this.movingInfo.mouseDownY);
    this.reSetSssStyle();
  }


  private buttonKeyUp(buttonCode: string, keys: string[], e: any): void {
    if (!keys) return;
    if (keys.indexOf(e.key) > -1) {
      this.buttonClick(buttonCode);
    }
  }

  public hideBottomBar(): void {
    this.isShowBottomBar = false;
  }

  public showBottomBar(): void {
    this.isShowBottomBar = true;
  }

  private twkTIO: any = null;

  public twinkleBorder(): void {
    clearTimeout(this.twkTIO);
    this.twinkleBorderCss = "twinkle-border";
    this.twkTIO = setTimeout(() => {
      this.twinkleBorderCss = "";
    }, 900);
  }
};
