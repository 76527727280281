import { Prop } from "vue-property-decorator";
import PopConfig from "@/components/pop/PopConfig";
import PopWin from "@/components/pop/PopWin.vue";
import PopResult from "@/components/pop/PopResult";
import ESVue from "@/comm/ESVue";

export default class PopLet extends ESVue {
  @Prop(Object) popInfo!: { id: number, config: PopConfig };

  protected getBaseCssClass(): string {
    return "";
  }

  mounted() {
    // @ts-ignore
    (this.$parent as PopWin).hookButtonCall(this.buttonCall);
  }

  protected getParams(): any {
    return this.popInfo?.config.params;
  }

  protected buttonCall(buttonCode: string): void {

  }

  protected closePopWin(result: PopResult): void {
    (this.$parent as PopWin).closePopWin(result);
  }

  protected hideBottomBar():void{
    (this.$parent as PopWin).hideBottomBar();
  }

  protected showBottomBar():void{
    (this.$parent as PopWin).showBottomBar();
  }

};