import { resolveComponent as _resolveComponent, createVNode as _createVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_IconSymbol = _resolveComponent("IconSymbol")!
  const _component_PopMange = _resolveComponent("PopMange")!
  const _component_router_view = _resolveComponent("router-view")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_IconSymbol),
    _createVNode(_component_PopMange, {
      ref: "popManage",
      onHookMouseMove: _ctx.hookMouseMove,
      onHookMouseUp: _ctx.hookMouseUp,
      onRemoveHookMouseMove: _ctx.removeHookMouseMove,
      onRemoveHookMouseUp: _ctx.removeHookMouseUp
    }, null, 8, ["onHookMouseMove", "onHookMouseUp", "onRemoveHookMouseMove", "onRemoveHookMouseUp"]),
    _createVNode(_component_router_view)
  ], 64))
}