
import { Options, Vue } from "vue-class-component";
import PopConfig from "@/components/pop/PopConfig";
import PopWin from "@/components/pop/PopWin.vue";
import PopResult from "@/components/pop/PopResult";
import PopItem from "@/components/pop/PopItem";
import Alert from "@/components/pop/Alert.vue";
import LetOfFileRename from "@/views/ide/filetree/LetOfFileRename.vue";
import LetOfNewFile from "@/views/ide/filetree/LetOfNewFile.vue";
import LetOfIDELogin from "@/views/ide/LetOfIDELogin.vue";
import LightConfig from "@/components/pop/LightConfig";
import LetOfProjectCreate from "@/views/projects/LetOfProjectCreate.vue";

@Options({
  components: { PopWin, Alert, LetOfFileRename, LetOfNewFile, LetOfIDELogin, LetOfProjectCreate }
})
export default class PopMange extends Vue {

  private popIdStep: number = 0;
  private pops: PopItem[] = [];

  private lightIdStep: number = 0;
  private lights: LightConfig[] = [];

  private popWinZIndexStep: number = 100;

  private maskInfo: { isShow: boolean, zIndex: number } = { isShow: false, zIndex: 100 };

  private defaultConfig(): PopConfig {
    return this.formatConfig({});
  }

  private formatConfig(config: PopConfig): PopConfig {
    if (!config.minWidth) {
      config.minWidth = "100px";
    }
    if (!config.minHeight) {
      config.minHeight = "20px";
    }
    if (!config.position) {
      config.position = { model: "percent", x: 50, y: 30 };
    }
    return config;
  }

  public popWin(config?: PopConfig): Promise<any> {

    config = config ? this.formatConfig(config) : this.defaultConfig();
    let zIndex: number = (this.popWinZIndexStep += 2);
    if (config.mask) {
      this.maskInfo.zIndex = zIndex - 1;
      if (!this.maskInfo.isShow) {
        this.maskInfo.isShow = true;
      }
    }
    let resolve: ((result: PopResult) => void) | undefined;
    let p = new Promise<any>((r) => {
      resolve = r;
    });

    this.pops.push({ id: ++this.popIdStep, zIndex, config, resolve });
    return p;
  }

  public closePopWin(pop: PopItem, result: PopResult): void {
    let pos: number = -1, maskIndex: number = -1;
    for (let i = 0; i < this.pops.length; i++) {
      let x = this.pops[i];
      if (pop.id == x.id) {
        pos = i;
      } else {
        if (x.config.mask) {
          if (maskIndex == -1) {
            maskIndex = x.zIndex - 1;
          } else {
            maskIndex = Math.max(maskIndex, x.zIndex - 1);
          }
        }
      }
    }
    if (pos > -1) {
      this.pops.splice(pos, 1);
      if (maskIndex > -1) {
        this.maskInfo.zIndex = maskIndex;
        this.maskInfo.isShow = true;
      } else {
        this.maskInfo.isShow = false;
      }
    }
    pop.resolve && pop.resolve(result);
  }

  public popLight(config: LightConfig): void {
    if (config.groupKey) {
      // 把同一组的先关闭
      let removes: LightConfig[] = [];
      this.lights.forEach(i => {
        if (i.groupKey == config.groupKey && i.id) {
          removes.push(i);
        }
      });
      removes.forEach(c => {
        this.closeLight(c);
      });

      config.id = ++this.lightIdStep;
      config.closed = false;
      this.lights.push(config);
      let light = this.lights[this.lights.length - 1];
      (light as any).closeTIO1 = setTimeout(() => this.closeLight(light), 5000);

    } else {

      let light = this.lights.find(light => light.message == config.message);
      if (light) {
        clearTimeout((light as any).closeTIO1);
        clearTimeout((light as any).closeTIO2);
        light.closed = false;
        light.count = typeof (light.count) == "undefined" ? 2 : light.count + 1;
      } else {
        config.id = ++this.lightIdStep;
        config.closed = false;
        this.lights.push(config);
        light = this.lights[this.lights.length - 1];
      }
      (light as any).closeTIO1 = setTimeout(() => this.closeLight(light), 5000);
    }
  }

  public lightCloseClick(light: LightConfig): void {
    this.closeLight(light);
  }

  private closeLight(light: any): void {
    clearTimeout(light.closeTIO1);
    clearTimeout(light.closeTIO2);
    light.closed = true;
    light.closeTIO2 = setTimeout(() => {
      let i = this.lights.indexOf(light);
      if (i > -1) {
        this.lights.splice(i, 1);
      }
    }, 1000);
  }

  private maskClick(): void {
    let z: number = 0, pop: any = null;
    for (let i = 0; i < this.pops.length; i++) {
      let x = this.pops[i];
      if (x.config.mask) {
        if (z == 0 || x.zIndex > z) {
          pop = x;
        }
      }
    }
    pop?.ref?.twinkleBorder();
  }
};
