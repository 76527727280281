
import IDEPopLet from "@/views/ide/IDEPopLet";
import PopResult from "@/components/pop/PopResult";

export default class LetOfIDELogin extends IDEPopLet {

  private isUnmounted: boolean = false;
  private loginType: string = "";
  private user: string = "";
  private pwd: string = "";
  private loginCode: string = "";

  mounted() {
    this.user = this.getParams()?.userInfo?.user || "";
    if (this.loginType == "pwd") {
      this.switchToPwd();
    } else {
      this.switchToWx();
    }
  }

  unmounted() {
    this.isUnmounted = true;
  }

  private switchToPwd(): void {
    if ("pwd" == this.loginType) return;
    clearTimeout(this.wxLFTIO);
    this.loginType = "pwd";
    this.showBottomBar();
    setTimeout(() => {
      if (this.user) {
        (this.$refs.inputPwd as HTMLElement).focus();
      } else {
        (this.$refs.inputUser as HTMLElement).focus();
      }
    }, 100);
  }

  private async switchToWx(): Promise<void> {
    if ("wx" == this.loginType) return;
    this.loginType = "wx";
    this.hideBottomBar();
    if (this.loginCode == "") {
      let result: any = await this.esPost("wx.login.show", {});
      this.loginCode = result.loginCode;
      this.wxLoginFlash();
    } else {
      this.wxLoginFlash();
    }
  }

  private wxLFTIO: any = null;

  private async wxLoginFlash(): Promise<void> {
    if (this.isUnmounted || "wx" != this.loginType) return;
    clearTimeout(this.wxLFTIO);
    let result: any = await this.esPost("wx.login.flash", { code: this.loginCode });
    switch (result.state) {
      case "READY":
        this.wxLFTIO = setTimeout(this.wxLoginFlash, 2000);
        break;
      case "EXPIRED":
        this.loginCode = result.loginCode;
        this.wxLFTIO = setTimeout(this.wxLoginFlash, 2000);
        break;
      case "DONE":
        this.closePopWin({ action: "E", result: { loginInfo: result } });
        break;
    }
  }

  protected buttonCall(buttonCode: string): void {
    if (buttonCode == "cancel") {
      this.closePopWin({ action: "B", buttonCode });
    } else if (buttonCode == "ok") {
      this.checkClose({ action: "B", buttonCode });
    }
  }

  private async checkClose(popResult: PopResult): Promise<void> {
    let user = `${this.user}`.trim();
    if (user == "") {
      await this.alert({ content: "用户名不能为空" });
      setTimeout(() => (this.$refs.inputUser as HTMLElement).focus(), 100);
      return;
    }
    let pwd = `${this.pwd}`;
    if (pwd == "") {
      await this.alert({ content: "密码不能为空" });
      setTimeout(() => (this.$refs.inputPwd as HTMLElement).focus(), 100);
      return;
    }
    popResult.result = { user, pwd };
    this.closePopWin(popResult);
  }

  private userKeyUp(e: any): void {
    switch (e?.key) {
      case "Escape":
        this.closePopWin({ action: "E" });
        break;
      case "Enter":
        (this.$refs.inputPwd as HTMLElement).focus();
        break;
    }
  }

  private pwdKeyUp(e: any): void {
    switch (e?.key) {
      case "Escape":
        this.closePopWin({ action: "E" });
        break;
      case "Enter":
        this.checkClose({ action: "E" });
        break;
    }
  }
};
