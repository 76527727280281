export enum ErrorLevel {
    /**
     * 系统级错误
     */
    System = 1,

    /**
     * 会话超时
     */
    SessionTimeout = 2,


    /**
     * 权限不足
     */
    PermissionDenied = 3,

    /**
     * 网络连接错误
     */
    NetworkError = 4,

    /**
     * 用户级错误
     */
    User = 99
}
