

import PGPopLet from "@/views/PGPopLet";
import PopResult from "@/components/pop/PopResult";

export default class LetOfProjectCreate extends PGPopLet {
  private projectName: string = "";
  private projectLanCode: string = "";
  private languages: { id: number, name: string, code: string }[] = [];

  mounted() {
    this.languages = this.getParams()?.languages || [];
  }



  protected buttonCall(buttonCode: string): void {
    if (buttonCode == "cancel") {
      this.closePopWin({ action: "B", buttonCode });
    } else if (buttonCode == "ok") {
      this.checkClose({ action: "B", buttonCode });
    }
  }


  private async checkClose(popResult: PopResult): Promise<void> {
    let name = `${this.projectName}`.trim();
    if (name == "") {
      await this.alert({ content: "项目名称不能为空" });
      return;
    }
    let language = `${this.projectLanCode}`;
    if (language == "") {
      await this.alert({ content: "请选择项目语目" });
      return;
    }
    popResult.result = { name, language };
    this.closePopWin(popResult);
  }
};
