import {Error} from "./error";
import {ErrorLevel} from "./error-level.enum";

export class MessageLine {

    private id: number;

    public getId(): number {
        return this.id;
    }


    public beforeSend ?: () => { cancelSystemLoading: boolean };

    /**
     * 发送前的hook，可在发送前执行一些方法，如显示自定义的加载动画。
     * @param fun 要执行的方法。
     */
    public onBeforeSend(fun: () => { cancelSystemLoading: boolean }): MessageLine {
        this.beforeSend = fun;
        return this;
    }


    public beforeReceived ?: () => { cancelSystemLoadDone: boolean };

    /**
     * 在收到完服务端返回数据后马上执行的方法。如关闭自定义的加载动画。
     * @param fun 要执行的方法。
     */
    public onBeforeReceived(fun: () => { cancelSystemLoadDone: boolean }): MessageLine {
        this.beforeReceived = fun;
        return this;
    }

    public received ?: (data: any) => void;

    /**
     * 在收到完服务端返回数据后将要执行的方法。
     * @param fun
     */
    public onReceived(fun: (data: any) => void): MessageLine {
        this.received = fun;
        return this;
    }


    private catchErrorMap: { [key: number]: boolean } | null | undefined;

    /**
     * 是否捕获指定的错误
     * @param level
     */
    public isCatchError(level: ErrorLevel): boolean {
        return (this.catchErrorMap && this.catchErrorMap[level]) || false;
    }

    public error?: (error: Error) => { cancelSystemError: boolean };

    /**
     * 在出现错误时要执行的方法。
     * @param fun 要执行的方法。
     * @param catchErrors 捕获的错误类型。
     */
    public onError(fun: (error: Error) => { cancelSystemError: boolean }, catchErrors ?: ErrorLevel[]): MessageLine {
        this.catchErrorMap = {};
        if (catchErrors != null) {
            for (let i = 0; i < catchErrors.length; i++) {
                this.catchErrorMap[catchErrors[i]] = true;
            }
        } else {
            this.catchErrorMap[ErrorLevel.PermissionDenied] = true;
            this.catchErrorMap[ErrorLevel.SessionTimeout] = true;
            this.catchErrorMap[ErrorLevel.System] = true;
            this.catchErrorMap[ErrorLevel.User] = true;
        }
        this.error = fun;
        return this;
    }

    public fireError(error: Error): void {
        this.error && this.error(error);
    }


    constructor(id: number) {
        this.id = id;
    }
}
