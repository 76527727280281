
import PopLet from "@/components/pop/PopLet";

export default class Alert extends PopLet {
  protected buttonCall(buttonCode: string): void {
    this.closePopWin({ action: "B", buttonCode });
  }


};
